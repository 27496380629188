/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


  $('#hamburger').click(function() {
    $(this).toggleClass('pokazi');
    $('body').toggleClass('pomjeri');
    $('#sideNav').toggleClass('pokazi');
  });

  $(document).keydown(function(e){
       
    if(e.keyCode === 27) {
        if ($('body').hasClass('pomjeri')) {
            $('body').removeClass('pomjeri');
        } 
        if($('#hamburger').hasClass('pokazi')) {
            $('#hamburger').removeClass('pokazi');
        }
        if($('#sideNav').hasClass('pokazi')) {
          $('#sideNav').removeClass('pokazi');
      }

    }
});

$('.singleSlide').matchHeight();

$("#sideNav ul.nav > li.menu-item-has-children > a").click(function (e) {
  e.preventDefault();
  $(this).parent('li').toggleClass('kliknuto');
  if (!$(e.target).closest("ul").is(".sub-menu")) {
      $(this).parent('li').not(this).siblings('li').removeClass('kliknuto');
  }     
});


 //inicijalizacija slidera
 $('#slider2').on('init', function(e, slick) {

  //load traka
  var $animirajLoad = $('div.slick-slide[data-slick-index="0"]').find('.divide');
  TweenMax.fromTo($animirajLoad, 4.2, {width: '0px'}, {width: '100%', delay: 0.5});
  });

  $('#slider2').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    fade: true,  
    autoplay: false,
    autoplaySpeed: 4500,
    speed: 1000,  
    pauseOnHover: false,
    appendArrows: $('#strelice'),
    appendDots: $('#nav'),
    prevArrow: '<button class="arrows prev"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button class="arrows next"><i class="fas fa-chevron-right"></i></button>',
  });



   //promjena slide-a (nextSlide)
   $('#slider2').on('beforeChange', function(event, slick, currentSlide, nextSlide){


    //load traka
    var $animirajLoad = $('div.slick-slide[data-slick-index="' + nextSlide + '"]').find('.divide');
    TweenMax.fromTo($animirajLoad, 5, {width: '0px'}, {width: '100%', delay: 0.7});
   });


   $('#sliderJLZ').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    fade: true,  
    autoplay: false,
    autoplaySpeed: 4500,
    speed: 1000,  
    pauseOnHover: false,
    appendArrows: $('#streliceJZL'),
    prevArrow: '<button class="arrows prev"><i class="fas fa-chevron-left"></i></button>',
    nextArrow: '<button class="arrows next"><i class="fas fa-chevron-right"></i></button>',
  });



  

  /*******************************header.banner*************************/

  var controllerHeader = new ScrollMagic.Controller();
  var tweenHeader = TweenMax.fromTo('header.banner', 0.6,
      {   autoAlpha: 0, y: -50  },
      {  autoAlpha: 1, y: 0, repeat: 0,  delay: 0.5,  ease: Expo.easeOut}
  );

  var sceneHeader = new ScrollMagic.Scene({
  triggerElement: 'header.banner',
  triggerHook: 'onEnter',
  reverse: true,
  duration: 0, 
  })

 .setTween(tweenHeader)
 .addTo(controllerHeader);

/***************************************************************/

  /*******************************#slider*************************/

  var controllerSlider = new ScrollMagic.Controller();
  var tweenSlider = TweenMax.fromTo('#slider', 0.4,
      {   autoAlpha: 0, y: -50  },
      {  autoAlpha: 1, y: 0, repeat: 0,  delay: 1.2,  ease: Expo.easeOut}
  );

  var sceneSlider = new ScrollMagic.Scene({
  triggerElement: '#slider',
  triggerHook: 'onEnter',
  reverse: true,
  duration: 0, 
  })

 .setTween(tweenSlider)
 .addTo(controllerSlider);

/***************************************************************/

  /*******************************fotka1*************************/

  var controllerf1 = new ScrollMagic.Controller();
  var tweenf1 = TweenMax.fromTo('.fotka1', 0.4,
      {   autoAlpha: 0, x: -50  },
      {  autoAlpha: 1, x: 0, repeat: 0,  delay: 0.2,  ease: Expo.easeOut}
  );

  var scenef1 = new ScrollMagic.Scene({
  triggerElement: '.fotka1',
  triggerHook: 'onEnter',
  reverse: true,
  duration: 0, 
  })

 .setTween(tweenf1)
 .addTo(controllerf1);

/***************************************************************/

  /*******************************fotka2*************************/

  var controllerf2 = new ScrollMagic.Controller();
  var tweenf2 = TweenMax.fromTo('.fotka2', 0.4,
      {   autoAlpha: 0, x: 50  },
      {  autoAlpha: 1, x: 0, repeat: 0,  delay: 0.6,  ease: Expo.easeOut}
  );

  var scenef2 = new ScrollMagic.Scene({
  triggerElement: '.fotka2',
  triggerHook: 'onEnter',
  reverse: true,
  duration: 0, 
  })

 .setTween(tweenf2)
 .addTo(controllerf2);

/***************************************************************/


  /*******************************JLZ*************************/

  var controllerJLZ = new ScrollMagic.Controller();
  var tweenJLZ = TweenMax.fromTo('#jesteLiZnali .fotka1 .innerWrap', 0.4,
      {   autoAlpha: 0, y: -50  },
      {  autoAlpha: 1, y: 0, repeat: 0,  delay: 1.4,  ease: Expo.easeOut}
  );

  var sceneJLZ = new ScrollMagic.Scene({
  triggerElement: '#jesteLiZnali .fotka1 .innerWrap',
  triggerHook: 'onEnter',
  reverse: true,
  duration: 0, 
  })

 .setTween(tweenJLZ)
 .addTo(controllerJLZ);

/***************************************************************/


  /***********************društvene mreže*************************/

var controlerDM = new ScrollMagic.Controller();
$('footer #drustveneMreze a').each(function() {


  var self = $(this);
  var tweenDM = new TimelineMax()
  .fromTo($(this), 1, {autoAlpha: 0, x: -70}, {autoAlpha: 1, x: 0, ease:Expo.easeOut, delay: 0.2}, '0');
  
  var sceneDM = new ScrollMagic.Scene({
  triggerElement: this,
  triggerHook: 'onEnter',
  duration: 0,
  offset: 0,
 })
  .setTween(tweenDM)
  .addTo(controlerDM);
  
});

  /*******************************footer sadrzaj*************************/

  var controllerFZ = new ScrollMagic.Controller();
  var tweenFZ = TweenMax.fromTo('footer .sadrzaj', 0.4,
      {   autoAlpha: 0, y: 50  },
      {  autoAlpha: 1, y: 0, repeat: 0,  delay: 0.4,  ease: Expo.easeOut}
  );

  var sceneFZ = new ScrollMagic.Scene({
  triggerElement: 'footer .sadrzaj',
  triggerHook: 'onEnter',
  reverse: true,
  duration: 0, 
  })

 .setTween(tweenFZ)
 .addTo(controllerFZ);

/***************************************************************/


  /*******************************copyright*************************/

  var controllerCopyright = new ScrollMagic.Controller();
  var tweenCopyright = TweenMax.fromTo('footer #copyright', 0.4,
      {   autoAlpha: 0, y:-30  },
      {  autoAlpha: 1, y: 0, repeat: 0,  delay: 0.4,  ease: Expo.easeOut}
  );

  var sceneCopyright = new ScrollMagic.Scene({
  triggerElement: 'footer #copyright',
  triggerHook: 'onEnter',
  reverse: true,
  duration: 0, 
  })

  
 .setTween(tweenCopyright)
 .addTo(controllerCopyright);

/***************************************************************/

$('[data-toggle="tooltip"]').tooltip({
  placement: 'bottom',
  offset: '0, -10',
});



})(jQuery); // Fully reference jQuery after this point.
